<template>
  <b-card
    header="Sign In"
    bg-variant="light"
  >
    <ValidationObserver
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <form
        class="mt-2 mb-4"
        novalidate
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <ValidationProvider
          #default="{ errors }"
          vid="email"
          name="email"
          rules="required|email"
        >
          <b-form-group>
            <label for="emailInput">Email</label>
            <input
              id="emailInput"
              v-model="credentials.email"
              type="email"
              :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
              aria-describedby="emailHelp"
              placeholder="Enter Email"
              :disabled="isBusy"
              autocomplete="off"
            >
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          #default="{ errors }"
          vid="password"
          name="password"
          rules="required"
        >
          <b-form-group>
            <label for="passwordInput">Password</label>
            <!-- <router-link
              to="/auth/password-reset1"
              class="float-right"
            >
              Forgot password?
            </router-link> -->
            <input
              id="passwordInput"
              v-model="credentials.password"
              type="password"
              :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
              placeholder="Enter Password"
              required
              :disabled="isBusy"
              autocomplete="off"
            >
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </b-form-group>
        </ValidationProvider>
        <div class="d-inline-block w-100">
          <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Remember Me</label>
        </div> -->
          <b-button
            type="submit"
            variant="success"
            block
            :disabled="isBusy"
            class="mb-1"
          >
            Sign in
          </b-button>
        </div>
        <!-- <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            Don't have an account?
            <router-link
              to="/sign-up"
              class="iq-waves-effect pr-4"
            >
              Sign up
            </router-link>
          </span>
        </div> -->
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService } from '@/services'
import { Oauth2 } from 'universal-social-auth'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    async onSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials).then(response => {
            this.$store.dispatch('auth/saveToken', response.data.token)

            SharedAuthService.FetchUser().then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            }).catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })

            this.isBusy = false
          }).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
              }
            }
            this.isBusy = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
</style>
